import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { GatsbyFluidImage } from '../../templates/template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'

// eslint-disable-next-line
import rowImage from '../../queries/images/row-image'

type GirlsWithTeacher = {
 girlsWithTeacher: GatsbyFluidImage
}

type Data = {
  data: GirlsWithTeacher
}

const StrategicInitiativesPage = ({ data }: Data) => {
  return (
    <Layout>
      <SEO
        title="Science Education Strategic Initiatives"
        description="BSCS Science Learning is an independent nonprofit offering science teachers instructional materials, research & development programs & training."
        canonicalUrl="https://bscs.org/our-work/strategic-initiatives/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Our Work
          </BreadcrumbItem>
          <BreadcrumbItem>
            Strategic Initiatives
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>Strategic Initiatives</PageTitle>
        <TitleBorder />
        <div className="block lg:hidden mt-5">
          <div>
            <div>
              <Img
                alt="Two girls and teacher in chemistry class."
                className="rounded-t"
                fluid={data.girlsWithTeacher.childImageSharp.fluid}
              />
              <div className="bg-bscs-gray-300 rounded-b p-2">
                <p className="text-sm mt-0 text-bscs-gray-800">Courtesy of Allison Shelley/The Verbatim Agency for American Education: Images of Teachers and Students in Action.</p>
              </div>
            </div>
            <p>Following a strategic planning process in 2016, BSCS Science Learning is pursuing three long-term, strategic initiatives.</p>
            <p id="21st-century-high-school-biology"><strong>21st Century High School Biology.</strong> We are launching an effort to reconceive high school biology to meet the needs of our students and society throughout the 21st century. As we convene scientists, educators, curriculum writers, community members, and advisors, we are exploring foundational questions, beginning with this one: What knowledge and skills in the life sciences will all students need for their lives and careers in the next 10-20 years?</p>
            <p><strong id="vip-professional-learning-for-21st-century-teaching">“VIP” Professional Learning for 21st Century Teaching.</strong> We are committed to bringing our transformative Video-based Inquiry-into-Practice (VIP) professional learning model to a broad audience of K-12 science teachers. Through more than a decade of research and development on our <Link to="/our-work/rd-programs/stella-science-teachers-learning-from-lesson-analysis">STeLLA<sup>®</sup></Link> professional learning program, we have documented the power of VIP professional learning to change teacher practice and improve student outcomes. We are exploring implementation and business models for nationwide dissemination.</p>

            <p id="equity-and-social-justice"><strong>Equity &amp; Social Justice.</strong> We are striving to break historical and institutional barriers to science for marginalized students and teachers. Our vision is that one day, all educators and learners will have access to a high quality science education, feel confident applying science effectively in their lives, and be prepared to understand and redress social and environmental injustices. We are exploring opportunities to pursue this initiative across our instructional materials development, teacher professional learning, leadership development, and educational research.</p>
          </div>
        </div>
        <div className="hidden lg:block">
          <div>
            <div className="ml-3 mb-3" style={{width: "40%", float: 'right'}}>
              <Img
                className="rounded-t"
                fluid={data.girlsWithTeacher.childImageSharp.fluid}
                alt="Two girls and teacher in chemistry class."
              />
              <div className="bg-bscs-gray-300 rounded-b p-2">
                <p className="text-sm mt-0 text-bscs-gray-800">Courtesy of Allison Shelley/The Verbatim Agency for American Education: Images of Teachers and Students in Action.</p>
              </div>
            </div>
            <p>
              Following a strategic planning process in 2016, BSCS Science Learning is pursuing three long-term, strategic initiatives.
            </p>
            <p>
              <strong>21st Century High School Biology.</strong> We are launching an effort to reconceive high school biology to meet the needs of our students and society throughout the 21st century. As we convene scientists, educators, curriculum writers, community members, and advisors, we are exploring foundational questions, beginning with this one: What knowledge and skills in the life sciences will all students need for their lives and careers in the next 10-20 years?
            </p>

            <p>
              <strong>“VIP” Professional Learning for 21st Century Teaching.</strong> We are committed to bringing our transformative Video-based Inquiry-into-Practice (VIP) professional learning model to a broad audience of K-12 science teachers. Through more than a decade of research and development on our <Link to="/our-work/rd-programs/stella-science-teachers-learning-from-lesson-analysis">STeLLA</Link> professional learning program, we have documented the power of VIP professional learning to change teacher practice and improve student outcomes. We are exploring implementation and business models for nationwide dissemination.
            </p>

            <p>
              <strong>Equity & Social Justice.</strong> BSCS believes that science learning should be inclusive, meaningful, and advance the work of social and environmental justice. We launched the <Link to="/our-work/equity-social-justice">Equity &amp; Social Justice Initiative (ESJI)</Link> in 2018 to define our role and take action toward redressing inequity and injustice in STEM, and in the world at large, through science education. Our commitment to equity and social justice is central to our mission and at the heart of everything we do.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default StrategicInitiativesPage

export const query = graphql`
  query {
    girlsWithTeacher: file(relativePath: { eq: "strategic-initiatives/girls-with-teacher.jpg" }) {
      ...rowImage
    }
  }
`
